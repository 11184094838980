exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-books-index-jsx": () => import("./../../../src/pages/books/index.jsx" /* webpackChunkName: "component---src-pages-books-index-jsx" */),
  "component---src-pages-coaching-index-jsx": () => import("./../../../src/pages/coaching/index.jsx" /* webpackChunkName: "component---src-pages-coaching-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-speaker-index-jsx": () => import("./../../../src/pages/speaker/index.jsx" /* webpackChunkName: "component---src-pages-speaker-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

